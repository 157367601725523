html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.footer {
  grid-row-start: 3;
  grid-row-end: 4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-hover {
  font-size: 18px;
}

.link-hover:hover {
  color: #ff5721 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0px;
}

.calendar {
  overflow: hidden;
}

.calendar th,
.calendar td {
  padding: 1em 2em;
}
@media all and (max-width: 768px) {
  .calendar th,
  .calendar td {
    padding: 5px;
  }
}

.calendar th {
  background: #efefef;
}

.calendar td {
  text-align: center;
}

.calendar .today {
  font-weight: bold;
  background-color: #eeeeee;
}

.calendar .active {
  background-image: linear-gradient(120deg, #e4893a 0%, #fdd4b0 100%);
  color: white;
  padding: 5px;
  display: inline-block;
  width: 30px;
  border-radius: 50%;
}

.calendar .has-trainer {
  background-image: linear-gradient(120deg, #22a54a 0%, #9eecb2 100%);
  color: white;
  padding: 5px;
  display: inline-block;
  width: 30px;
  border-radius: 50%;
}

.month-selector th {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: capitalize;
}

.month-cell:hover {
  background: #efefef;
  cursor: pointer;
}

.select-month-title {
  font-weight: bold;
  background: #efefef;
}

.toggle-month {
  text-align: center !important;
}

.toggle-month:hover,
.week-day:hover {
  cursor: pointer;
  background: #efefef;
}

.arrow-icon {
  padding: 5px 0 0 5px;
}

.calendar .selected-day {
  color: white;
  font-weight: bold;
  background-color: #e48a3a;
}

.paper {
  padding: 0.25em 1.5em 1.5em 1.5em;
  display: 'flex';
  overflow: 'auto';
  flex-direction: 'column';
}

svg.MuiSvgIcon-root {
  transition: 250ms ease;
}
svg.MuiSvgIcon-root:hover {
  cursor: pointer;
  opacity: 0.5;
}

.nav-bar {
  text-align: center;
}

.react-quiz-container {
  margin: 0 auto !important;
  text-align: center;
  width: 100% !important;
}

.filter-dropdown-select {
  display: none;
}
